import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';

const News: React.FC = () => {
  const navigate = useNavigate();
  const { lng } = useParams<{ lng?: string }>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!lng) {
      // If no language is specified, redirect to default language
      navigate('/en/news', { replace: true });
    } else if (i18n.language !== lng) {
      // Change the language if it's different from the current language
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n, navigate]);

  return (
    <div>
      <Header />

      {/* Main Content */}
      <main className="flex-1 my-5 mx-auto px-4 md:px-8 lg:px-16">
        <section className="section">
          <h3 className="section-title">{t('articles.TUDaIdeaContest.title')}</h3>
          <p className="mb-4 whitespace-pre-line">{t('articles.TUDaIdeaContest.content')}</p>
            <div className="flex-col-center">
              <img
                src="/assets/images/TU_Ideenwettbewerb24.png"
                alt={t("articles.TUDaIdeaContest.title")}
                className="img-large"
              />
            </div>
          <br />
          <br />
          <h3 className="section-title">{t('articles.DissertationBB.title')}</h3>
          <p className="mb-4 whitespace-pre-line">{t('articles.DissertationBB.content')}
             <a href="http://www.shaker.de/shop/978-3-8440-9622-4" style={{ color: "blue" }}>
              ISBN: 978-3-8440-9622-4 </a>
          </p>
            <div className="flex-col-center">
              <img
                src="/assets/images/BlatBelmonte_PhD_thesis_cover1.png"
                alt={t("articles.DissertationBB.title")}
                className="img-large"
              />
            </div>
          <br />
          <br />
          <h3 className="section-title">{t('articles.article1.title')}</h3>
          <p className="mb-4 whitespace-pre-line">{t('articles.article1.content')}</p>
          {/*<h3 className="section-title">{t('articles.soonInfo')}</h3>*/}
        </section>

        {/* Contact Section */}
        <section className="section text-center">
          <p className="mb-2">{t("contactUs")}</p>
          <p>
            {t("writeUs")}{" "}
            <a href="mailto:info@fenexity.com" className="link">
              info@fenexity.com
            </a>
          </p>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default News;
