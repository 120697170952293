import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ImprintContent from "../components/ImprintContent";

const Imprint: React.FC = () => {
  const navigate = useNavigate();
  const { lng } = useParams<{ lng?: string }>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!lng) {
      // If no language is specified, redirect to default language
      navigate("/en/imprint", { replace: true });
    } else if (i18n.language !== lng) {
      // Change the language if it's different from the current language
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n, navigate]);

  return (
    <div>
      <Header />

      <ImprintContent />

      <Footer />
    </div>
  );
};

export default Imprint;