import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams<{ lng?: string }>();
  const currentLng = lng || i18n.language;

  return (
    <footer className="footer">
      <div className="flex flex-col items-center">
        <img
          src="/assets/images/logo_simple_bright.webp"
          alt="Fenexity Logo"
          className="w-64 h-auto mb-4"
        />
        <img
          src="/assets/images/Fenexity-managing-eneflex-black-Quick.png"
          alt="Fenexity Managing Eneflex"
          className="h-12 w-auto mb-4"
        />
        <div className="mt-2">
          <Link to={`/${currentLng}/imprint`} className="footer-link">
            {t('imprint')}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
