import React, { useState } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navigation: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { lng } = useParams<{ lng?: string }>();
  const navigate = useNavigate();

  const currentLng = lng || i18n.language;
  const supportedLangs = ['en', 'de', 'es']; // Add other supported languages here

  // State to track whether the mobile menu is open
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle mobile menu visibility
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Handle language change
  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLang = e.target.value;
    const currentPath = window.location.pathname;
    const newPath = currentPath.replace(/^\/[a-z]{2}/, `/${selectedLang}`);
    i18n.changeLanguage(selectedLang);
    navigate(newPath, { replace: true });
  };

  return (
    <nav className="navbar bg-fenexityGray w-full shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center h-16">
        {/* Brand or Logo */}
        <div className="flex-shrink-0">
          <NavLink to={`/${currentLng}`} className="text-xl font-bold text-black">
            Fenexity
          </NavLink>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:block">
          <ul className="navbar-menu flex space-x-4">
            <li>
              <NavLink
                to={`/${currentLng}`}
                end /* Ensure "Home" only matches exactly '/' */
                className={({ isActive }) =>
                  isActive ? 'navbar-link navbar-link-active' : 'navbar-link'
                }
              >
                {t('home')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${currentLng}/news`}
                className={({ isActive }) =>
                  isActive ? 'navbar-link navbar-link-active' : 'navbar-link'
                }
              >
                {t('news')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${currentLng}/about-us`}
                className={({ isActive }) =>
                  isActive ? 'navbar-link navbar-link-active' : 'navbar-link'
                }
              >
                {t('aboutUs')}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/${currentLng}/contact`}
                className={({ isActive }) =>
                  isActive ? 'navbar-link navbar-link-active' : 'navbar-link'
                }
              >
                {t('contact')}
              </NavLink>
            </li>
            <li className="ml-4">
              <label htmlFor="language-select" className="mr-2 text-black">
                {t('selectLanguage')}:
              </label>
              <select
                id="language-select"
                value={currentLng}
                onChange={handleLanguageChange}
                className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
              >
                {supportedLangs.map((lang) => (
                  <option key={lang} value={lang}>
                    {t(lang)}
                  </option>
                ))}
              </select>
            </li>
          </ul>
        </div>

        {/* Mobile menu toggle (hamburger) */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="menu-icon text-black">
            &#9776; {/* Hamburger icon */}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden ${menuOpen ? 'block' : 'hidden'} bg-fenexityGray`}>
        <ul className="space-y-2 px-4 pb-3">
          <li>
            <NavLink
              to={`/${currentLng}`}
              end /* Ensure "Home" only matches exactly '/' */
              className={({ isActive }) =>
                isActive ? 'navbar-link block navbar-link-active' : 'navbar-link block'
              }
              onClick={() => setMenuOpen(false)} // Close menu on link click
            >
              {t('home')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${currentLng}/news`}
              className={({ isActive }) =>
                isActive ? 'navbar-link block navbar-link-active' : 'navbar-link block'
              }
              onClick={() => setMenuOpen(false)} // Close menu on link click
            >
              {t('news')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${currentLng}/about-us`}
              className={({ isActive }) =>
                isActive ? 'navbar-link block navbar-link-active' : 'navbar-link block'
              }
              onClick={() => setMenuOpen(false)} // Close menu on link click
            >
              {t('aboutUs')}
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/${currentLng}/contact`}
              className={({ isActive }) =>
                isActive ? 'navbar-link block navbar-link-active' : 'navbar-link block'
              }
              onClick={() => setMenuOpen(false)} // Close menu on link click
            >
              {t('contact')}
            </NavLink>
          </li>
          <li className="ml-4">
            <label htmlFor="language-select" className="mr-2 text-black">
              {t('selectLanguage')}:
            </label>
            <select
              id="language-select"
              value={currentLng}
              onChange={handleLanguageChange}
              className="border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
            >
              {supportedLangs.map((lang) => (
                <option key={lang} value={lang}>
                  {t(lang)}
                </option>
              ))}
            </select>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;
