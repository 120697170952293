// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LanguageRedirect from './components/LanguageRedirect';
import Home from "./pages/00-00-Home";
import News from "./pages/01-00-News";
import AboutUs from "./pages/02-00-AboutUs";
import Contact from "./pages/03-00-Contact";
import Imprint from "./pages/99-00-Imprint";

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LanguageRedirect />} />
        <Route path="/:lng" element={<Home />} />
        <Route path="/:lng/news" element={<News />} />
        <Route path="/:lng/about-us" element={<AboutUs />} />
        <Route path="/:lng/contact" element={<Contact />} />
        <Route path="/:lng/imprint" element={<Imprint />} />
      </Routes>
    </Router>
  );
};

export default App;
