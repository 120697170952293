import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Contact: React.FC = () => {
  const navigate = useNavigate();
  const { lng } = useParams<{ lng?: string }>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!lng) {
      // If no language is specified, redirect to default language
      navigate('/en/contact', { replace: true });
    } else if (i18n.language !== lng) {
      // Change the language if it's different from the current language
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n, navigate]);

  return (
    <div>
      <Header />

      {/* Main Content */}
      <main className="flex-1 my-5 mx-auto px-4 md:px-8 lg:px-16">

        {/* Contact Section */}
        <section className="section text-center">
          <p className="mb-2">{t("contactUs")}</p>
          <p>
            {t("writeUs")}{" "}
            <a href="mailto:info@fenexity.com" className="link">
              info@fenexity.com
            </a>
          </p>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Contact;
