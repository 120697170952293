// src/components/LanguageRedirect.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LanguageRedirect: React.FC = () => {
  const { i18n } = useTranslation();

  // Get the user's preferred language from the browser
  const userLang = navigator.language.split("-")[0];
  const supportedLangs = ["en", "de", "es"]; // Add your supported languages here
  const language = supportedLangs.includes(userLang) ? userLang : "en";

  // Change the language in i18n
  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }

  // Redirect to the language-specific route
  return <Navigate to={`/${language}`} replace />;
};

export default LanguageRedirect;
