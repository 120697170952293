import React from 'react';
import { useTranslation } from 'react-i18next';
import Navigation from './Navigation';

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <header className="relative bg-blue-500 text-blue-800">
      {/*<div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">*/}
      {/*  <div className="flex items-center justify-between">*/}
      {/*    <img*/}
      {/*      src="/assets/images/logo_simple_bright.webp"*/}
      {/*      alt="Logo"*/}
      {/*      className="h-12"*/}
      {/*    />*/}
      {/*    <h1 className="text-xl md:text-3xl font-bold">*/}
      {/*      {t('welcomeMessage')}*/}
      {/*    </h1>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* Add Navigation */}
      <Navigation />
    </header>
  );
};

export default Header;
