import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const { lng } = useParams<{ lng?: string }>();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!lng) {
      // If no language is specified, redirect to default language
      navigate("/en", { replace: true });
    } else if (i18n.language !== lng) {
      // Change the language if it's different from the current language
      i18n.changeLanguage(lng);
    }
  }, [lng, i18n, navigate]);

  return (
    <div>
      <Header />

      {/* Main Content */}
      <main className="flex-1 my-5 mx-auto px-4 md:px-8 lg:px-16">
        {/* Section with Image */}
        <section className="flex justify-center">
          <img
            src="/assets/images/Fenexity_bird.png"
            alt="Fenexity Bird"
            className="img-large"
          />
        </section>

        {/* Vision, Mission, Values Section */}
        <section className="section">
          <div className="text-center">
            <div className="flex flex-col">
              <h3 className="section-title">{t("vision")}</h3>
              <p className="mb-4">{t("vision_txt")}</p>
              <h3 className="section-title">{t("mission")}</h3>
              <p className="mb-4">{t("mission_txt")}</p>
              <h3 className="section-title">{t("values")}</h3>
              <p>{t("values_txt")}</p>
            </div>
          </div>
        </section>

        {/* Flexibility Management Section */}
        <section className="section">
          <h3 className="section-title">{t("wemanageFlex")}</h3>
          <div className="section-grid">
            <div className="flex-col-center">
              <img
                src="/assets/images/e-bus_transparent.png"
                alt={t("eBus")}
                className="img-large"
              />
              <h3 className="text-xl mt-4">{t("eBus")}</h3>
              <p>{t("eBus_txt")}</p>
            </div>
            <div className="flex-col-center">
              <img
                src="/assets/images/e-truck_transparent.png"
                alt={t("eTruck")}
                className="img-large"
              />
              <h3 className="text-xl mt-4">{t("eTruck")}</h3>
              <p>{t("eTruck_txt")}</p>
            </div>
          </div>
        </section>

        {/* What We Offer Section */}
        <section className="section">
          <h3 className="section-title">{t("weOffer")}</h3>
          <div className="flex flex-col items-center">
            <p className="mb-4">{t("weOffer_txt")}</p>
          </div>
          <div className="flex-row-center">
            <img
              src="/assets/images/logo2_transparent.png"
              alt="ArrowPulse Logo"
              className="img-logo"
            />
            <div>
              <p>{t("arrowpulseDescr")}</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section className="section text-center">
          <p className="mb-2">{t("contactUs")}</p>
          <p>
            {t("writeUs")}{" "}
            <a href="mailto:info@fenexity.com" className="link">
              info@fenexity.com
            </a>
          </p>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Home;